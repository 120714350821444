import React from "react"
import { GatsbySeo } from "gatsby-plugin-next-seo"
import { SiteMetaConfig } from "gatsby-theme-0/src/config/config-data"
import Header from "gatsby-theme-0/src/components/header"
import Footer from "gatsby-theme-0/src/components/footer"
import ContactWidget from "gatsby-theme-0/src/utils/contactwidget"
import Policy from "gatsby-theme-0/src/config/bone/policy"
import "../templates/post.css"


const HelpPage = ({ location }) => (
  
  <>
  <GatsbySeo
    title={`Help | ${SiteMetaConfig.site_name}`}
    description={SiteMetaConfig.site_description}
    canonical={`${SiteMetaConfig.site_url}${location.pathname}`}
    openGraph={{
      type: "website",
      url: `${SiteMetaConfig.site_url}${location.pathname}`,
      title: `Help | ${SiteMetaConfig.site_name}`,
      description: SiteMetaConfig.site_description,
      images: [
        { url: SiteMetaConfig.site_image },
      ],
      site_name: SiteMetaConfig.site_name,
    }}
    twitter={{
      handle: `@${SiteMetaConfig.site_twitter_handle}`,
      site: `@${SiteMetaConfig.site_name}`,
      cardType: "summary_large_image",
    }}
    facebook={{
      appId: SiteMetaConfig.site_facebook_app_id,
    }}
    htmlAttributes={{
      lang: SiteMetaConfig.site_language,
    }}
  />
  <Header />
  <ContactWidget>
    <details id="help_1662984820045" className="Collapse_Card">
      <summary id="help_1662984821550" className="Collapse_Title">Refund Policy</summary>
      <div id="help_1662984822933" className="Policy_Container">
        <h1>REFUND POLICY</h1>
        <em>Last updated at&nbsp;{new Date().toLocaleString('en-US', {month: 'long'})}&nbsp;01,&nbsp;{new Date().getFullYear()}</em>
        <p>Thanks for shopping at {SiteMetaConfig.site_name}. We appreciate the fact that you like to buy the stuff we build. We also want to make sure you have a rewarding experience while you’re exploring, evaluating, and purchasing our products. As with any shopping experience, some terms and conditions apply to transactions at {SiteMetaConfig.site_name}. We’ll be as brief as our attorneys will allow.</p>
        <p>Every single product that you choose is thoroughly inspected, checked for defects, and created with utmost care. We do this to ensure that you fall in love with our products. If you have any questions about whether a product is right for you, feel free to contact us by using the contact form provided above before making your purchase.</p>
        <p>Since {SiteMetaConfig.site_name} offers non-tangible irrevocable goods we DO NOT issue refunds once the order is accomplished and the link is sent. As a customer, you are responsible for understanding this upon purchasing any item at {SiteMetaConfig.site_name}.</p>
        <p><b>UNFORTUNATELY, DUE TO THE IMMEDIATE ACCESS TO DIGITAL PRODUCTS, ALL PURCHASES ARE FINAL AND NONREFUNDABLE.</b></p>
        <p>Since your purchase is a digital product, it is deemed “used” after receiving the link, and all purchases made on {SiteMetaConfig.site_name} are non-refundable or exchangeable. Since the products made available here are intangible, there is a strict No Refund policy.</p>
        <p>However, we realize that exceptional circumstances can take place with regard to the character of the product we supply.</p>
        <p>Therefore, we sometimes DO honor requests for a refund for the following reasons:</p>
        <ul>
          <li><b>Non-delivery of the product:</b> due to some mailing issues with your e-mail provider or your mail server you might not receive a delivery e-mail from us. In this case, we recommend contacting us for assistance. Claims for non-delivery must be submitted within 14 hours from the order placing date. Otherwise, the product will be considered received and used.</li>
          <li><b>Major defects:</b> although all the products are thoroughly tested before release, unexpected errors may occur. You should contact us for such issues. We reserve the right to rectify the error or defect within 144 hours. If any deficiency is approved and we fail to correct it within 144 hours from the date of the initial complaint letter or any other notification provided by you, the refund will be issued to you in full without any compensations or reimbursements. OR, at your’s choice, replacement of the product of the same or around the same value can be offered. Please be advised that temporary access to your devices or third-party-accounts can be requested by our technicians in order to identify and fix the possible issues with our products in your specific scenario. Failure to provide such access in a timely manner may result in a delayed resolution of the issue. Refusal to provide access to your devices and third-party-accounts will result in your inability to qualify for a refund.</li>
          <li><b>Product not-as-described:</b> such issues should be reported within 14 hours from the date of the purchase. Clear evidence must be provided proving that the purchased product is not as it is described on the website. Complaints that are based merely on your’s false expectations or wishes are not honored.</li>
        </ul>
        <p>Please note that we do not bear any responsibility and therefore we do not satisfy any refund/return/exchange requests based on incompatibility of our products with some devices as our products use your device's native functions to work properly. We don’t guarantee that our products are fully compatible with all devices as our product uses the functions of your device to work properly and we do not provide support for device incompatibility issues as it is solely your responsibility to make sure our product works perfectly on your device before making your purchase.</p>
        <p>Requests for a refund are accepted at {SiteMetaConfig.site_name} within 14 hours after the order is placed. You should accompany this request with extensively detailed and grounded reasons why you are applying for a refund. Please make sure your request does not contradict our Terms And Conditions/Privacy Policy.</p>
        <p>Sadly, there are times when we may not have the product(s) that you choose in stock or may face some issues with our inventory and quality check. In such cases, we may have to cancel your order. You will be intimated about it as soon as possible so that you don't have to worry unnecessarily about your order. You will be refunded once our team confirms your request.</p>
        <p>{SiteMetaConfig.site_name} reserves the right to amend any information, including but not limited to prices, technical specifications, terms of purchase, and product or service offerings without prior notice.</p>
        <p>By using our platform or making a purchase, you hereby consent to our Refund Policy and agree to its terms. We update, amend or make any changes to this document so that they accurately reflect our Service and policies. Unless otherwise required by law, those changes will be prominently posted here. Then, if you continue to use our service, you will be bound by the updated Refund Policy. If you do not want to agree to this or any updated Refund Policy, you can stop using our service. For any reason, if you are not completely satisfied with any good or service that we provide, don't hesitate to contact us by using the contact form provided above and we will discuss any of the issues you are going through with our product and service.</p>
      </div>
    </details>
    <Policy />
  </ContactWidget>
  <Footer CustomBottomHeight="yes" />
  </>
)

export default HelpPage