import React from "react"


const Policy = () => {

	return(
    <></>
  );
};

export default Policy